<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
        <div class="iq-card-body">
          <div class="image-block" v-if="eveImgObj && eveImgObj['cover_pic'] && eveImgObj['cover_pic']['image']">
            <img :src="eveImgObj['cover_pic']['image']" class="img-fluid rounded w-100" alt="blog-img">
          </div>
          <div class="image-block" style="height: 460px;" v-else>
            <div style="background-color: #EEEEEE; width: 100%; height: 100%; border-radius: 5px; display: flex; text-align: center;">
              <span style="margin: auto; font-size: 110px;">{{ getFirstLetterOfAString(eveObj['event_name']) }}</span>
            </div>
          </div>
          <div class="blog-description mt-2" v-if="eveObj">
            <b-badge
              class="border mr-2 mb-2 font-weight-normal"
              variant="primary"
              style="font-size: 11px; font-weight: bold !important;"
            >
              {{eveObj.event_master_type == 'Gide+' ? 'Gide Plus' : 'For All'}}
            </b-badge>
            <h5 class="row mb-3 pb-3 border-bottom">
              <div class="col-12">
                {{ eveObj.event_name }}
              </div>
              <div class="col-12 mt-1">
                <!-- share SocialMediaShare  -->
                <b-btn variant="primary" class="button_share pull-right ml-2">
                  <div v-if="eveObj && eveObj.event_id" class="d-flex flex-fill justify-content-center align-items-center share">
                    <SocialMediaShare :propShareUrl="VUE_APP_SERVER_URL + '/public/GideEvent/' + eveObj.event_id"></SocialMediaShare>
                  </div>
                </b-btn><!-- share SocialMediaShare  -->
                <b-btn class="pull-right ml-1" size="sm" v variant="primary" v-if="userPermission('event_edit')"  @click="duplicateEvent()">
                  <i class="fa fa-files-o" title="Duplicate This Event"></i>
                </b-btn>
                <b-btn class="pull-right" size="sm" v variant="primary" v-if="userPermission('event_edit')"  @click="goToEditEve()">
                  Edit
                </b-btn>
                <b-btn class="pull-right mr-2" variant="primary" v-if="!eveObj.event_rsvp_added && !eveObj.event_finished && (eveObj.event_master_type !== 'Gide+' || (eveObj.event_master_type == 'Gide+' && gidePlusData.subs_id))" size="sm"  :disabled="eveObj.event_rsvp_added ? true : false" @click="setRsvpForUser()">
                  {{ eveObj.event_rsvp_added ? '' : registerBtnText }}
                </b-btn>
                <b-btn class="pull-right mr-2" variant="primary" v-else-if="!eveObj.event_rsvp_added && !eveObj.event_finished && (eveObj.event_master_type == 'Gide+' && !gidePlusData.subs_id)" size="sm"  :disabled="eveObj.event_rsvp_added ? true : false" @click="upgradeToGidePlus()">
                  Become a Student Club Member
                </b-btn>
                <section class="ml-0" v-if="eveObj.event_allowed_role">
                  <b-badge class="mr-2" v-for="eveUserRole in eveObj.event_allowed_role.split(',')" :key="eveUserRole">
                    {{userRolesJson[eveUserRole]}}
                  </b-badge>
                  <b-badge class="mr-2 bg-warning animate float-right" v-if="eveObj.aff_code">
                    <span class="animate">Affiliate Code: {{eveObj.aff_code}}</span>
                  </b-badge>
                  <b-badge class="mr-2 mb-2" style="background-color: #1993ff !important;" v-if="eveObj.event_finished">
                      Event Completed
                  </b-badge>
                </section>
              </div>
            </h5>
            <div class="row blog-meta d-flex align-items-center mb-3">
            <div class="col-sm-12 col-md-4 col-lg-4 date">
               <i class="ri-calendar-2-fill text-primary pr-2"></i>{{ eveObj.event_start_ts | dateFormatDDMMYYYYHHMMA }}
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4" @click="showRsvpList()" style="cursor: pointer;">
              <i class="fa fa-user text-primary pr-2"></i>Registered Users: <span class="primary-color">{{ eveObj.event_rsvp_list_count }}</span>
            </div>
            <div @click="ratingModalOpen()" class="col-sm-12 col-md-4 col-lg-4 rating_comments">
              <div>Rating:</div>
                <div v-for="index in 5" :key="index" class="rating ratingInput">
                  <label v-if="eveObj.event_finished && index <= eveObj.rating" class="selected">☆</label>
                  <label v-else class="unselected">☆</label>
                </div>
              </div>
            </div>
            <div class="row">
              <small class="col-12">
                Start date: {{ eveObj.event_start_date|dateFormatDDMMYYYY }}&nbsp;/&nbsp;
                End date: {{ eveObj.event_end_date|dateFormatDDMMYYYY }}
              </small><br>
            </div>
            <div class="">
              <span v-if="eveObj.event_type && eveObj.event_type.event_type_name" class="mr-2">Activity Type:</span>
              <b-badge
                class="border mr-2 mb-2 font-weight-normal"
                variant="none"
                v-if="eveObj.event_type && eveObj.event_type.event_type_name">
                <span>{{ eveObj.event_type.event_type_name }}</span>
              </b-badge>
              <span class="mr-2 ml-3" v-if="eveObj.event_level && eveObj.event_level.event_lev_name">
                Activity Level:
              </span>
              <b-badge
                class="border mr-2 mb-2 font-weight-normal"
                variant="none"
                v-if="eveObj.event_level && eveObj.event_level.event_lev_name">
                <span>{{ eveObj.event_level.event_lev_name }}</span>
              </b-badge>
            </div>
            <p v-if="eveObj.event_weblink && eveObj.event_weblink.includes('thankyou')" class="d-flex align-content-center">
              <b-badge class="pull-left mt-2 mr-2" style="font-size: 14px; font-weight: bold !important;" variant="primary">Registered</b-badge>
            </p>
            <p v-else-if="eveObj.event_rsvp_added && !eveObj.event_finished" class="d-flex align-content-center">
              <b-btn class="pull-left mt-2 mr-2" variant="primary" size="sm" @click="utility.openUrlInNewTab(eveObj.event_weblink)">
                {{(eveObj.event_hosting_platform == eventHostingPlatformConfig.ZOOM ? 'Click here to join' : "Click Here to Continue")}}
              </b-btn>
              <!-- <b-btn v-if="(userData.user_role === 'USERROLE11115' || userData.user_role === 'USERROLE11116') && eveObj.event_hosting_platform.includes('HSM')" class="pull-left mt-2 mr-2" variant="primary" size="sm" @click="unsubscribeEvent()">
                Unsubscribe
              </b-btn> -->
              <br>
              <template v-if="eveObj.duration_left_start_meeting">
                <span v-if="!eveObj.duration_left_start_meeting.includes('-')">
                  [ {{eveObj.event_hosting_platform == eventHostingPlatformConfig.QUIZ_COMPETITION ? 'Quiz Cometition' : 'Meeting'}} will start in {{eveObj.duration_left_start_meeting}} ]
                </span>
                <span v-if="eveObj.duration_left_start_meeting.includes('-') && !eveObj.duration_left_end_meeting.includes('-')">
                  [ {{eveObj.event_hosting_platform == eventHostingPlatformConfig.QUIZ_COMPETITION ? 'Quiz Cometition' : 'Meeting'}} will end in {{eveObj.duration_left_end_meeting}} ]
                </span>
              </template>
              <br>
              <span v-if="eveObj.event_hosting_platform == eventHostingPlatformConfig.ZOOM" style="color: #E67300">
                  *Please don't share this meeting url with anyone. Else you will not able to join the meeting.
              </span>
            </p>
            <div v-if="eveObj.event_meeting_params && eveObj.event_meeting_params.start_url" class="mb-2 mt-3">
              <b-button variant="primary" @click="utility.openUrlInNewTab(eveObj.event_meeting_params.start_url)">
                Start {{eveObj.event_hosting_platform == eventHostingPlatformConfig.QUIZ_COMPETITION ? 'Quiz Cometition' : 'Meeting'}}
              </b-button>
              <b-button class="ml-3" variant="primary" @click="utility.openUrlInNewTab(eveObj.event_meeting_params.join_url)">
                Join {{eveObj.event_hosting_platform == eventHostingPlatformConfig.QUIZ_COMPETITION ? 'Quiz Cometition' : 'Meeting'}}
              </b-button>
            </div>
            <p class="vhtmlTag vueHtmlParent mt-3" v-html="eveObj.event_desc">
            </p>
            <div class="mt-5" v-if="eveInterestListObj && eveInterestListObj.length > 0">
              <b-badge
                v-for="interest in eveInterestListObj"
                :key="interest.interest_id"
                class="border mr-2 mb-2 font-weight-normal"
                variant="primary">
                <span>{{ interest.interest_name }}</span>
              </b-badge>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 gide_right_mobile_data">
      <div class="iq-card iq-card-block iq-card-stretch blog-post">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="iq-card-title">
              Upcoming Events
            </h4>
          </div>
        </div>
        <hr class="m-0"/>
        <div class="iq-card-body" v-if="eveListObj && eveListObj.length > 0">
          <ul class="list-inline p-0 mb-0 mt-2">
            <li class="mb-3" v-for="(eve, index) in eveListObj" :key="index+'LIST'">
              <div class="d-flex align-items-top pb-3 border-bottom">
                <div class="col-md-5">
                  <div class="image-block" style="height: 80px;" v-if="eveListImgObj && eveListImgObj[eve.event_id] && eveListImgObj[eve.event_id]['cover_pic']">
                    <img :src="eveListImgObj[eve.event_id]['cover_pic']['image']" class="img-fluid rounded w-100" style="height: 80px;" alt="blog-img">
                  </div>
                  <div v-else style="background-color: #EEEEEE; width: 100%; height: 80px; border-radius: 5px; display: flex; text-align: center;">
                    <span style="margin: auto; font-size: 36px;">{{ getFirstLetterOfAString(eve['event_name']) }}</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="blog-description pl-2">
                    <div class="date mb-1">
                      <a @click="goToEVE(eve)"  style="cursor: pointer;" tabindex="-1">{{ eve.event_start_date | dateFormatMMDOYYYY }}</a>
                    </div>
                    <h6 class="mb-2">
                      {{ eve.event_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="iq-card-body" v-else>New events coming soon...</div>
      </div>
      <CareerHomeList v-if="!isMobileDevice"/>
    </div>

    <div class="col-lg-8" v-if="eventMentionListArr && eventMentionListArr.length">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog user-comment">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="iq-card-title">
              In Association With
            </h4>
          </div>
        </div>
        <div class="iq-card-body">
          <div class="row">
            <div class="col-lg-4" v-for="mention in eventMentionListArr" :key="mention.id">
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog" style="cursor: pointer;" @click="goToModule(mention)">
                <div class="iq-card-body">
                  <div class="d-flex align-items-center">
                    <div class="user-image mb-3">
                      <img v-if="mention.org_profile_img || mention.club_profile_img" :src="mention.org_profile_img ? mention.org_profile_img : mention.club_profile_img" class="avatar-80 rounded" alt="#" data-original-title="" title="">
                      <b-avatar v-else class="avatar-80 rounded">
                        {{ getFirstLetterOfAString(mention.name) }}
                      </b-avatar>
                    </div>
                    <div class="ml-3">
                      <h5>
                        {{ mention.name }}
                      </h5>
                      <p>
                        {{ mention.module_type_name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <RatingAdd v-if="showRatingModal" :propModuleId="eveId" :propModuleName="'EVENT'" :propOpenedInModal="showRatingModal"></RatingAdd>
    </div>

    <!-- <eventRsvpList :propShowEVERsvpModal="propShowEVERsvpModal" :propEVEId="eveId" /> -->

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>

    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToEventPlanList()"/>

    <template v-if="showPaymentModal && eveId">
      <b-modal v-model="showPaymentModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true">
        <Paygw :propModuleType="modName" :propModuleName="eveObj.event_name" :propModuleObjId="eveId" @emitPaymentStatus="emitPaymentStatus" :propEventMktSrc="source" :propRedirect="`/event/${eveId}`"/>
        <template #modal-footer="">
          <b-button @click="showPaymentModal = false" size="sm" class="pull-left">
            Close
          </b-button>
        </template>
      </b-modal>
    </template>

    <!-- Resistration full Modal -->
    <template v-if="showRSVPLimitModal">
      <b-modal v-model="showRSVPLimitModal" no-close-on-backdrop no-close-on-esc size="xxl" :noCloseOnBackdrop="true">
        <p v-html="rsvpSeatsFullMessage"></p>
      </b-modal>
    </template><!-- Resistration full Modal -->
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { events } from "../../../FackApi/api/events.js"
// import eventRsvpList from "./EventRsvpList.vue"
import AlertBox from "../../../components/AlertBox.vue"
import Paygw from "../../../views/Gide/Paygw/Paygw.vue"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import eventHostingPlatformConfig from "../../../FackApi/json/EventHostingPlatforms.js"
import RatingAdd from "../Rating/RatingAdd.vue"
import userRolesJson from "../../../FackApi/json/UserRoles.json"
import userPermission from "../../../Utils/user_permission"
import SocialMediaShare from "../../../components/SocialMediaShare.vue"

export default {
  name: "EventView",
  components: {
    // eventRsvpList,
    AlertBox,
    CareerHomeList,
    Paygw,
    RatingAdd,
    SocialMediaShare
  },
  data () {
    return {
      apiName: "event_view",
      cvTitle: "Event",
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      VUE_APP_SERVER_URL: process.env.VUE_APP_SERVER_URL,
      eveId: null,
      eveImgObj: {},
      eveObj: {},
      eveListObj: {},
      eveListImgObj: {},
      rsvpUserListObj: {},
      submitting: false,
      showEVEModal: false,
      toastVariant: "",
      showToast: false,
      toastMsg: "",
      toastTitle: "Events",
      cvLoadingStatus: false,
      propShowEVERsvpModal: false,
      eventMentionListArr: [],
      eveInterestListObj: [],
      showUpgradeAlertBox: false,
      alertDesc: "This event is available to Gide Plus members only. Please upgrade to Gide Plus before registering for this event",
      alertTitle: "Events",
      registerBtnText: "Register",
      okText: "Upgrade",
      cancelText: "Not now",
      utility: Utility,
      showRatingModal: false,
      eventHostingPlatformConfig: eventHostingPlatformConfig,
      showPaymentModal: false,
      modName: "event", // Module name
      userRolesJson: userRolesJson,
      source: "ONLINE",
      showRSVPLimitModal: false,
      rsvpSeatsFullMessage: `Due to overwhelming interest, we've reached full capacity for registrations and unfortunately, we cannot accept any more participants at this time. We truly appreciate the incredible response we've received from all of you.<br><br>However, please don't be disheartened! We're continuously exploring options to accommodate more individuals and enhance our programs in the future.<br><br>Stay tuned for updates on upcoming activities`
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    sourceData () {
      return this.$store.getters["SourceTracker/getUserSource"]
    },
    gidePlusData () {
      return this.$store.getters["Paygw/myPlanState"]
    }
  },
  mounted () {
    socialvue.index()
    this.eventHostingPlatformConfig = eventHostingPlatformConfig(this.userData.user_role)

    // if sourceData is available then store in source else take user defined value to store in source
    this.source = this.$route.query.source ? this.$route.query.source : this.sourceData

    this.loadRequiredData()
  },
  methods: {
    /**
     * userPermission
     */
    userPermission (actionName = null) {
      return userPermission(this, this.userData, actionName)
    },
    /**
     * emitPaymentStatus
    */
    emitPaymentStatus (event) {
      this.registerBtnText = "Click Here To Continue"
      this.showPaymentModal = false
    },
    /**
     * ratingModalOpen
     *
     */
    ratingModalOpen () {
      if (this.eveObj.event_finished) {
        // Only for past events
        this.showRatingModal = true
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData (eveId = false) {
      if (!eveId) {
        this.eveId = this.$route.params.event_id
      }
      else {
        this.eveId = eveId
      }

      this.submitting = false
      await this.eventView(this.eveId)

      if (!this.isMobileDevice) {
        await this.eventList(this.eveId)
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getResizedText
     */
    getResizedText (text) {
      return text ? text.substr(0, 100) : ""
    },
    /**
     * goToEveView
     */
    goToEveView () {
      console.log("goToEveView")
    },
    /**
     * goToEditEve
     */
    goToEditEve () {
      this.$router.push("/event_edit/" + this.eveId)
    },
    /**
     * eventView
     */
    async eventView (eveId = false) {
      try {
        this.eveObj = {}
        this.eveImgObj = {}
        this.eventMentionListArr = []
        this.eveObj.event_type = null
        this.eveObj.event_level = null
        let eventId = null

        if (eveId) {
          eventId = eveId
        }
        else {
          eventId = this.eveId
        }

        let eventViewResp = await events.eventView(this, eventId)
        if (eventViewResp && eventViewResp.resp_status && eventViewResp.resp_data.data.event_id) {
          this.eveObj = eventViewResp.resp_data.data
          this.eveObj.duration_left_start_meeting = Utility.getDiffDateTime(new Date(), this.eveObj.event_start_date)
          this.eveObj.duration_left_end_meeting = Utility.getDiffDateTime(new Date(), this.eveObj.event_end_date)

          this.eveObj.event_rsvp_list_count = eventViewResp.event_rsvp_list_count // Save the Registration Count

          if (eventViewResp.event_images && Object.keys(eventViewResp.event_images).length > 0) {
            this.eveImgObj = eventViewResp.event_images[eventId]
          }

          // Eve Type
          if (eventViewResp.event_type && Object.keys(eventViewResp.event_type).length > 0) {
            this.eveObj.event_type = eventViewResp.event_type
          }
          else {
            this.eveObj.event_type = null
          }

          // Eve Level
          if (eventViewResp.event_level && Object.keys(eventViewResp.event_level).length > 0) {
            this.eveObj.event_level = eventViewResp.event_level
          }
          else {
            this.eveObj.event_level = null
          }

          // Mentions
          if (eventViewResp.event_mentions && eventViewResp.event_mentions.length > 0) {
            for (let index in eventViewResp.event_mentions) {
              let mention = eventViewResp.event_mentions[index]
              if (mention.club_id) {
                this.eventMentionListArr.push(
                  { name: `${mention.club_name}`, id: mention.club_id, "module_type": "CLUB", "module_type_name": "Club", "module_data": mention, "club_profile_img": mention.club_profile_img }
                )
              }
              else if (mention.org_id) {
                this.eventMentionListArr.push(
                  { name: `${mention.org_name}`, id: mention.org_id, "module_type": "UNIV", "module_type_name": "University", "module_data": mention, "org_profile_img": mention.org_profile_img }
                )
              }
            }
          }

          // Interests
          if (eventViewResp.event_interests && eventViewResp.event_interests.length > 0) {
            this.eveInterestListObj = eventViewResp.event_interests
          }
        }
        else {
          this.$router.push(`/events`)
        }
      }
      catch (err) {
        console.error("Exception occurred at eventView() and Exception:", err.message)
      }
    },
    /*
     * eventList
     * */
    async eventList (eveId = false) {
      try {
        let eventId = null
        if (eveId) {
          eventId = eveId
        }
        else {
          eventId = this.eveId
        }

        let filter = {
          "limit": 5,
          "upcoming_events": true,
          "user_role": this.userData.user_role
        }

        let eveListResp = await events.eventList(this, filter)
        if (eveListResp && eveListResp.resp_status) {
          this.eveListObj = eveListResp.resp_data.data.filter(obj => {
            return obj.event_id !== eventId
          })
          this.eveListImgObj = eveListResp.event_images
          this.rsvpUserListObj = eveListResp.rsvp_users
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
    },
    /**
     * goToEventPlanList
     */
    goToEventPlanList () {
      this.$router.push("/subscription_plans")
    },
    /**
     * goToEVE
     */
    async goToEVE (eve) {
      if (eve.event_id) {
        this.submitting = false
        this.showEVEModal = true
        await this.loadRequiredData(eve.event_id)
      }
    },
    /**
     * setRsvpForUser
     */
    async setRsvpForUser () {
      // Check if seats are available
      if (this.eveObj.event_rsvp_list_count >= this.eveObj.event_rsvp_limit) {
        // Seats Over. Show Warning
        this.showRSVPLimitModal = true
        return
      }

      this.cvLoadingStatus = true
      try {
        let payload = {
          module_obj_id: this.userData.user_id,
          module_name: this.userRolesJson[this.userData.user_role].toUpperCase(),
          event_id: this.eveId,
          event_name: this.eveObj.event_name,
          event_is_paid: this.eveObj.event_is_paid === 0 ? "0" : this.eveObj.event_is_paid,
          user_email: this.userData.user_email,
          user_mobile: this.userData.user_mobile,
          event_mkt_src: "GIDEAPP"
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
        ) {
          // @todo shubham If More then one organisation then show model to select univ
          // Univ
          payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          payload.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
        ) {
          payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          payload.module_name = "ORG"
        }

        let eveRsvpResp = await events.eventRsvpAdd(this, payload)
        if (!eveRsvpResp.resp_status && eveRsvpResp.resp_code === "ERR_USER_NOT_SUBSCRIBED") {
          this.showUpgradeAlertBox = true
        }
        else if (!eveRsvpResp.resp_status && eveRsvpResp.resp_code === "ERR_SUBS_PLAN_PURCHASE_REQUIRED") {
          ApiResponse.responseMessageDisplay(this, eveRsvpResp)
          const options = {
            title: "Activity Purchase",
            size: "md",
            buttonSize: "md",
            okVariant: "primary",
            okTitle: "Pay",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          }

          let message = "This is a paid activity. Kindly click on the 'Pay' button to continue"
          const value = await this.$bvModal.msgBoxConfirm(message, options)
          if (value) {
            this.showPaymentModal = true
          }
        }
        else if (!eveRsvpResp.resp_status &&
          (eveRsvpResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER" ||
            eveRsvpResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER"
          )
        ) {
          ApiResponse.responseMessageDisplay(this, eveRsvpResp)

          const selectedPlanForModule = {
            module_obj_id: this.eveObj.event_id,
            module: "event",
            module_obj_name: this.eveObj.event_name
          }

          await this.$store.commit("Paygw/addSelectedModuleForPlanCommit", selectedPlanForModule)
          this.$router.push("/subscription_plans?type=custom")
        }
        else if (!eveRsvpResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, eveRsvpResp)
        }
        else if (eveRsvpResp.resp_status) {
          this.eveObj = { event_rsvp_added: true, ...eveRsvpResp.resp_data }
        }

        if (eveRsvpResp && eveRsvpResp.resp_status) {
          this.eveObj.event_weblink = eveRsvpResp.resp_data.event_weblink
          this.eveObj.event_rsvp_added = true

          if (this.eveObj.event_weblink) {
            window.open(this.eveObj.event_weblink, "_blank")
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at setRsvpForUser() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * unsubscribeEvent
     */
    async unsubscribeEvent () {
      let payload = {
        module_obj_id: this.userData.user_id,
        module_name: "STUDENT",
        event_id: this.eveId
      }

      if (this.userData.user_role === "USERROLE11115" &&
        this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
      ) {
        // @todo shubham If More then one university then show model to select univ
        // Univ
        payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
      }
      else if (this.userData.user_role === "USERROLE11116" &&
        this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
      ) {
        payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
      }

      const unsubscribeEventResp = await events.eventRsvpUnsubscribe(this, payload)
      if (!unsubscribeEventResp.resp_status) {
        return ApiResponse.responseMessageDisplay(this, unsubscribeEventResp)
      }

      this.eveObj.event_rsvp_added = null
    },
    /**
     * showRsvpList
     */
    showRsvpList () {
      this.propShowEVERsvpModal = Math.random()
    },
    /**
     * goToModule
     */
    goToModule (module) {
      if (module.module_type === "CLUB") {
        this.$router.push("/club/" + module.id)
      }
      else {
        this.$router.push("/organisations/" + module.id)
      }
    },
    /**
     * upgradeToGidePlus
     */
    upgradeToGidePlus () {
      this.$router.push("/subscription_plans")
    },
    /**
     * duplicateEvent
     */
    async duplicateEvent () {
      try {
        const duplicateEventResp = await events.eventDuplicate(this, { event_id: this.$route.params.event_id })
        if (!duplicateEventResp.resp_status) {
          return
          // handle toast message
        }
        window.open(`/event/${duplicateEventResp.resp_data.event_id}`, "_blank")
        // reditrect to the duplicated event page
      }
      catch (err) {
        console.error("Exception in duplicateEvent and err: ", err)
      }
    }
  }
}
</script>

<style scoped>
@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
}
.animate {
  animation:  blink 1s linear infinite;
}
</style>
